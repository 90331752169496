import axios from "@/config/http";
export const MallAPI = {
  goodsInquiry(id) {
    return axios.get(`/mall/v1/index/goodsInquiry/` + id);
  },

  buyGood(params) {
    return axios.post(`/mall/v1/order`, params);
  },

  list(params) {
    return axios.get(`/mall/v1/order/list`, { params });
  },

  getDetection(params) {
    return axios.get("/mall/plan/orderTrace", { params });
  },

  checkStatus() {
    return axios.get("/trade/status");
  },

  // 订单每一个页签数量
  async tabsNum() {
    return await axios.get(`/mall/v1/order/getOrderListData`);
  },

  // 星级接口
  async evaluationStar(params) {
    return await axios.get(`/mall/v1/index/factroyLevel`, { params });
  },
};
